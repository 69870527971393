import React from 'react'
import Layout from "../components/layout.js"
import SEO from "../components/seo"

export default () => (
    <Layout>
        <SEO title='Contact Me' description="Contact Isabelle Wagenvoord"/>
        <h1>Contact Me</h1>
        <p>
            If you have anything to say please don't hesitate to
            reach out!
        </p>
        <div id="mail-me-btn">
            <a href="mailto:isabellewagenvoord@gmail.com" 
                className='blue-box'>
                email me
            </a>
        </div>
    </Layout>
)